import React from 'react'
import { MdCheckCircle } from 'react-icons/md'

class ThankYou extends React.Component {
	render() {
		return (
			<div className="d-flex flex-column text-center align-items-center py-4">
				<MdCheckCircle className="h1 text-success" />
				<h5>Thank you for your feedback!</h5>
			</div>
		)
	}
}
export default ThankYou;