import React from 'react';
import { getSurveyData } from './actions/get-survey-data';
import { sendSurveyData } from './actions/send-survey-data';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { MdFileDownload } from "react-icons/md";
import { MdPrint } from "react-icons/md";
import Loading from './components/loading';
import Survey from './components/survey';
import moment from 'moment';
import FileSaver from 'file-saver';
import NoTicket from './components/no-ticket';
import { getSurveySettings } from './actions/get-survey-settings';

class Root extends React.Component {
  state = {
    businessId: '',
    techCode: '',
    itemId: '',
    ticketImage: '',
    surveyType: '1', //0: upvote or downvote 1: 5"star" questions
    logins: '',
    loading: false,
    showSurvey: true,
    showThankYou: false,
    showReviewLinks: false,
    completed: 0,
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    surveyComment: '',
    linkClicked: '',
    score: '', //for 4 question survey
    rating: '', //up or down 'score'
    inviteToReviewThreshold: null,
    showComment: false,
    answerAllQuestionsWarning: false,
    noTicket: false,
    invitePresented: false
  }

  componentDidMount() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      if (id.indexOf('-') !== -1) {
        const itemId = id.split('-')[0];
        const verifyKey = id.split('-')[1];
        this.setState({ itemId, verifyKey }, () => {
          this.loadData();
        });
      } else {
        const itemId = id;
        const verifyKey = false;
        this.setState({ itemId, verifyKey }, () => {
          this.loadData();
        });
      }

    } catch (err) {
      this.setState({ noTicket: true, loading: false, showSurvey: false });
    }
  }

  async loadData() {
    this.setState({ loading: true });
    const { itemId, verifyKey } = this.state;
    const results = await getSurveyData({itemId});
    if (results) {
      if (_.get(results.data[0], 's3_ticket_url')) {
        if (_.get(results.data[0], 'VerifyKey') && verifyKey !== _.get(results.data[0], 'VerifyKey')) {
          this.setState({ noTicket: true, loading: false, showSurvey: false });
        } else {
          const cyberSettings = await getSurveySettings({ businessId: _.get(results.data[0], 'ClientID') });
          this.setState({
            loading: false,
            ticketImage: _.get(results.data[0], 's3_ticket_url'),
            linksOffered: _.get(cyberSettings.data, 'review_links'),
            surveyType: _.get(results.data[0], 'survey_type_offered'),
            completed: _.get(results.data[0], 'IsCmplt'),
            verifyKey: _.get(results.data[0], 'VerifyKey'),
            businessId: _.get(results.data[0], 'ClientID'),
            invoiceNumber: _.get(results.data[0], 'InvNmbr'),
            inviteToReviewThreshold: cyberSettings?.data?.survey_present_third_party_threshold,
            emailToNotifyThreshold: cyberSettings?.data?.survey_notify_low_threshold,
          });
        }

      } else {
        this.setState({ noTicket: true, loading: false, showSurvey: false });
      }
      return true;
    }
  }

  async sendSurveyData() {
    const surveyData = {
      businessId: this.state.businessId,
      invoiceNumber: this.state.invoiceNumber,
      techCode: this.state.techCode,
      itemId: this.state.itemId,
      question1: this.state.question1,
      question2: this.state.question2,
      question3: this.state.question3,
      question4: this.state.question4,
      rating: this.state.rating,
      comment: this.state.surveyComment,
      linkClicked: this.state.linkClicked,
      completed: this.state.completed,
      whenCompleted: moment().format('YYYY-MM-DD HH:mm:ss'),
      invitePresented: this.state.invitePresented
    }
    const results = await sendSurveyData(surveyData);
    if (results) {
      return true;
    }
  }

  handleShowSurvey() {
    this.setState({ showSurvey: true });
  }

  handleCloseSurvey() {
    this.setState({ showSurvey: false });
  }

  handleRadioClick(question, value) {
    question === 'question1' && this.setState({ question1: value }, () => {
      this.handleShowComment();
    });
    question === 'question2' && this.setState({ question2: value }, () => {
      this.handleShowComment();
    });
    question === 'question3' && this.setState({ question3: value }, () => {
      this.handleShowComment();
    });
    question === 'question4' && this.setState({ question4: value }, () => {
      this.handleShowComment();
    });
    this.sendSurveyData();
  }

  handleShowComment() {
    const { question1, question2, question3, question4, inviteToReviewThreshold } = this.state;
    if (question1 > 0 && question2 > 0 && question3 > 0 && question4 > 0) {
      let scores = [question1, question2, question3, question4];
      let totalScore = parseInt(question1) + parseInt(question2) + parseInt(question3) + parseInt(question4);
      let averageScore = totalScore / scores.length;
      if (averageScore < parseInt(inviteToReviewThreshold)) {
        this.setState({ showComment: true });
      } else {
        this.setState({ showComment: false});
      }
    }
  }

  handleRadioSubmit() {
    const { question1, question2, question3, question4, inviteToReviewThreshold } = this.state;
    if (question1 && question2 && question3 && question4) {
      this.setState({ completed: '1', showThankYou: true, answerAllQuestionsWarning: false }, () => {
        this.sendSurveyData();
        let scores = [question1, question2, question3, question4];
        let totalScore = scores.reduce((previous, current) => current += previous);
        let averageScore = totalScore / scores.length;
        if (averageScore >= inviteToReviewThreshold) {
            this.setState({
              showReviewLinks: true,
              invitePresented: true
            }, () => {
              this.sendSurveyData();
            });
        } else {
          setTimeout(() => {
            this.handleCloseSurvey()
          }, 1500);
        }
      });
    } else {
      this.setState({ answerAllQuestionsWarning: true, completed: '0' });
    }
  }

  handleSurveyComment = (comment) => {
    this.setState({ surveyComment: comment });
  }

  handleLinkClick = (link) => {
    this.setState({
      linkClicked: link,
      showSurvey: false
    }, () => {
        this.sendSurveyData();
    });
  }

  printTicket() {
    window.print();
  }

  downloadTicket() {
    FileSaver.saveAs(`${this.state.ticketImage}`); //currently opens ticket in new window, needs to download directly
  }

  render() {
    const { ticketImage, showSurvey, linksOffered, surveyType, answerAllQuestionsWarning, completed } = this.state;
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.noTicket) {
      return (
        <NoTicket />
      );
    }
    return (
      <div className="main-wrapper vh-100">
        <div className="container d-flex justify-content-center flex-column align-items-center">
          <div className="w-100 border p-4 shadow-sm rounded my-3" >
            <img className="w-100 mb-3 h-100" src={ticketImage} alt="Ticket for work done." />
            <div className="w-100 d-flex justify-content-center">
              <Button className="no-print me-2 btn-lg btn-secondary" type="submit" onClick={() => this.downloadTicket()}><MdFileDownload /> Download</Button>
              <Button className="no-print btn-lg btn-secondary" onClick={() => this.printTicket()}><MdPrint /> Print</Button>
            </div>
          </div>
        </div>
        {showSurvey && <Survey
          state={this.state}
          show={this.state.showSurvey}
          handleCloseSurvey={() => this.handleCloseSurvey()}
          urls={linksOffered}
          linkClicked={(link) => this.handleLinkClick(link)}
          handleShowSurvey={() => this.handleShowSurvey()}
          handleRadioClick={(question, value) => this.handleRadioClick(question, value)}
          handleRadioSubmit={() => this.handleRadioSubmit()}
          handleSurveyComment={(comment) => this.handleSurveyComment(comment)}
          showThankYou={this.state.showThankYou}
          surveyType={surveyType}
          answerAllQuestionsWarning={answerAllQuestionsWarning}
          surveyAlreadyCompleted={completed}/>}
      </div>
    );
  }

}

export default Root;

