import React from 'react';
import { Alert } from 'react-bootstrap';

class Warning extends React.Component {
	render() {
		return (
			<Alert className="my-2" variant="warning">
				{this.props.message}
			</Alert>
		)
	}
}
export default Warning;