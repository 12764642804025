import React from 'react';
import GoogleLogo from '../images/google.png';
import YelpLogo from '../images/yelp.png';
import AngiesListLogo from '../images/angies-list.png';
import MantaLogo from '../images/manta.png';
import FacebookLogo from '../images/facebook.png';
import YellowPagesLogo from '../images/yellowpages.png';
import BbbLogo from '../images/bbb.png';
import _ from 'lodash';



class ReviewLinks extends React.Component {
	render() {
		const urls = JSON.parse(this.props.urls);
		const google = _.get(urls, 'google.link');
		const yelp = _.get(urls, 'yelp.link');
		const angieslist = _.get(urls, 'angieslist.link');
		const facebook = _.get(urls, 'facebook.link');
		const manta = _.get(urls, 'manta.link');
		const yellowpages = _.get(urls, 'yellowpages.link');
		const bbb = _.get(urls, 'bbb.link');
		return (
			<div>
				<h5 className="text-center">We'd greatly appreciate if you'd quickly post a review for us (just click below).</h5>
				<div className="d-flex flex-column justify-content-center py-2">
					{google &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={google} onClick={() => this.props.linkClicked(google)}>
							<img src={GoogleLogo} alt="Google Reviews" />
						</a>
					}
					{yelp &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={yelp} onClick={() => this.props.linkClicked(yelp)}>
							<img src={YelpLogo} alt="Yelp Reviews" />
						</a>
					}
					{angieslist &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={angieslist} onClick={() => this.props.linkClicked(angieslist)}>
							<img src={AngiesListLogo} alt="Angieslist Reviews" />
						</a>
					}
					{facebook &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={facebook} onClick={() => this.props.linkClicked(facebook)}>
							<img src={FacebookLogo} alt="Angieslist Reviews" />
						</a>
					}
					{manta &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={manta} onClick={() => this.props.linkClicked(manta)}>
							<img src={MantaLogo} alt="Angieslist Reviews" />
						</a>
					}
					{yellowpages &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={yellowpages} onClick={() => this.props.linkClicked(yellowpages)}>
							<img src={YellowPagesLogo} alt="Angieslist Reviews" />
						</a>
					}
					{bbb &&
						<a className="review-link" target="_blank" rel="noopener noreferrer" href={bbb} onClick={() => this.props.linkClicked(bbb)}>
							<img src={BbbLogo} alt="BBB Reviews" />
						</a>
					}
				</div>
			</div>
		)
	}
}
export default ReviewLinks