import React from 'react';
import { Alert } from 'react-bootstrap';

class NoTicket extends React.Component {
	render() {
		return (
			<div className="container py-3 vh-100 d-flex align-items-center justify-content-center">
				<Alert className="my-2" variant="warning">
					We're sorry, it appears there is no ticket for the provided invoice.
				</Alert>
			</div>
		)
	}
}
export default NoTicket;