import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

class Loading extends React.Component {
	render() {
		return (
			<div className="w-100 vh-100 mx-auto d-flex align-items-center justify-content-center py-5">
				<Spinner animation="border" variant="primary" />
			</div>
		)
	}
}
export default Loading