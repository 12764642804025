import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ThankYou from './thankyou-message'
import ReviewLinks from './review-links'
import QuestionRatings from './question-ratings'
import Warning from './warning'
import { Fragment } from 'react'

class Survey extends React.Component {
	state = (this.props.state);
	// businessId: '0777',
	// techCode: 'AS',
	// invoiceNumber: '2228',
	// ticketImage: '',
	// surveyType: '1', //0: upvote or downvote 1: 5"star" questions
	// logins: '',
	// loading: false,
	// showSurvey: true,
	// showThankYou: false,
	// showReviewLinks: false,
	// question1: '',
	// question2: '',
	// question3: '',
	// question4: '',
	// linkClicked: '',
	componentDidMount() {
		if (this.props.surveyAlreadyCompleted === 1) {
			this.props.handleCloseSurvey();
		}
	}

	dismissOptions(choice) {
		this.setState({
			showOptions: false,
			showThankYou: true,
		});
		if (choice) {
			this.setState({ showReviewLinks: this.props.urls });
		} else {
			setTimeout(() => {
				this.props.handleCloseSurvey()
			}, 1500);
		}
	}

	render() {
		return (
			<Fragment>
				<Modal show={this.props.show} onHide={this.props.handleCloseSurvey} centered>
					{!this.props.showThankYou &&
						<Modal.Header className="d-block text-center">
							<Modal.Title>5-Second Feedback</Modal.Title>
							<p className="text-muted mb-0">Your review helps us grow</p>
						</Modal.Header>
					}
					<Modal.Body>
						{this.props.showThankYou &&
							<ThankYou />
						}
						{!this.props.showThankYou &&
							<div>
								<QuestionRatings
									handleRadioClick={(question, value) => this.props.handleRadioClick(question, value)}
									handleRadioSubmit={() => this.props.handleRadioSubmit()}
									handleSurveyComment={(comment) => this.props.handleSurveyComment(comment)}
									state={this.props.state} />
							</div>

						}
						{this.props.state.showReviewLinks &&
							<ReviewLinks urls={this.props.urls} linkClicked={this.props.linkClicked} />
						}
						{this.props.answerAllQuestionsWarning &&
							<Warning message="Please choose an option for each prompt." />
						}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" className="btn btn-block d-block modal-footer-link text-center" onClick={this.props.handleCloseSurvey}>
							Just show me my ticket
						</Button>
					</Modal.Footer>
				</Modal>
			</Fragment>
		);
	}
}
export default Survey