import { getBaseUrl } from '../utilities/constants';
import _ from 'lodash';

export async function sendSurveyData(surveyData) {
	try {
		const data = {
			item_id: encodeURIComponent(_.get(surveyData, 'itemId')),
			completed: parseInt(surveyData.completed),
			when_completed: surveyData.whenCompleted,
			question_1: encodeURIComponent(_.get(surveyData, 'question1')),
			question_2: encodeURIComponent(_.get(surveyData, 'question2')),
			question_3: encodeURIComponent(_.get(surveyData, 'question3')),
			question_4: encodeURIComponent(_.get(surveyData, 'question4')),
			rating: encodeURIComponent(_.get(surveyData, 'rating')),
			comment: encodeURIComponent(_.get(surveyData, 'comment')),
			links_clicked: encodeURIComponent(_.get(surveyData, 'linkClicked')),
			invite_presented: encodeURIComponent(_.get(surveyData, 'invitePresented')),
			business_id: encodeURIComponent(_.get(surveyData, 'businessId')),
			invoice_number: encodeURIComponent(_.get(surveyData, 'invoiceNumber'))
		}
		const requestResults = await fetch(`${getBaseUrl()}/v3/sendSurveyResults`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		const objectData = await requestResults.json();

		if (requestResults.status > 199 && requestResults.status < 400) {
			return objectData;
		}
	} catch (err) {
		console.log('sendSurveyData failed with error: ' + err);
	}

	return false;
}