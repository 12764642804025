import { getBaseUrl } from '../utilities/constants';

export async function getSurveySettings({ businessId }) {
	try {
		const requestResults = await fetch(`${getBaseUrl()}/v3/publicCyberTheme?&business_id=${encodeURIComponent(businessId)}`);
		const objectData = await requestResults.json();
		if (requestResults.status > 199 && requestResults.status < 400) {
			return objectData;
		}
	} catch (err) {
		console.log('getThirdPartyCredentials failed with error: ' + err);
	}

	return false;
}