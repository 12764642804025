import React from 'react'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { ToggleButtonGroup } from 'react-bootstrap'
import { BsStarFill } from "react-icons/bs";

class QuestionRatings extends React.Component {

	handleSurveyCommentChange = (event) => {
		this.props.handleSurveyComment(event.target.value)
	}

	render() {
		const stateProp = this.props.state;
		return (
			<div className="py-2">
				<div className="survey-btn-group-wrapper">
					<h5 className="text-center mb-n2">Office staff</h5>
					<ToggleButtonGroup  toggle className="mb-3 survey-btn-group reverse" name="question1">
						<ToggleButton type="radio" name="question1" value="10" className="survey-btn" onChange={() => this.props.handleRadioClick('question1', 10)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="question1" value="8" className="survey-btn" onChange={() => this.props.handleRadioClick('question1', 8)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="question1" value="6" className="survey-btn" onChange={() => this.props.handleRadioClick('question1', 6)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="question1" value="4" className="survey-btn" onChange={() => this.props.handleRadioClick('question1', 4)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="question1" value="2" className="survey-btn" onChange={() => this.props.handleRadioClick('question1', 2)}><BsStarFill /></ToggleButton>
					</ToggleButtonGroup>
				</div>

				<div className="survey-btn-group-wrapper">
					<h5 className="text-center mb-n2">Technician performance</h5>
					<ToggleButtonGroup toggle className="mb-3 survey-btn-group reverse" name="question2">
						<ToggleButton type="radio" name="radio" value="10" className="survey-btn" onChange={() => this.props.handleRadioClick('question2', 10)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="8" className="survey-btn" onChange={() => this.props.handleRadioClick('question2', 8)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="6" className="survey-btn" onChange={() => this.props.handleRadioClick('question2', 6)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="4" className="survey-btn" onChange={() => this.props.handleRadioClick('question2', 4)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="2" className="survey-btn" onChange={() => this.props.handleRadioClick('question2', 2)}><BsStarFill /></ToggleButton>
					</ToggleButtonGroup>
				</div>

				<div className="survey-btn-group-wrapper">
					<h5 className="text-center mb-n2">Value of service</h5>
					<ToggleButtonGroup toggle className="mb-3 survey-btn-group reverse" name="question3">
						<ToggleButton type="radio" name="radio" value="10" className="survey-btn" onChange={() => this.props.handleRadioClick('question3', 10)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="8" className="survey-btn" onChange={() => this.props.handleRadioClick('question3', 8)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="6" className="survey-btn" onChange={() => this.props.handleRadioClick('question3', 6)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="4" className="survey-btn" onChange={() => this.props.handleRadioClick('question3', 4)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="2" className="survey-btn" onChange={() => this.props.handleRadioClick('question3', 2)}><BsStarFill /></ToggleButton>
					</ToggleButtonGroup>
				</div>

				<div className="survey-btn-group-wrapper">
					<h5 className="text-center mb-n2">Likelihood to use us again</h5>
					<ToggleButtonGroup toggle className="mb-3 survey-btn-group reverse" name="question4">
						<ToggleButton type="radio" name="radio" value="10" className="survey-btn" onChange={() => this.props.handleRadioClick('question4', 10)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="8" className="survey-btn" onChange={() => this.props.handleRadioClick('question4', 8)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="6" className="survey-btn" onChange={() => this.props.handleRadioClick('question4', 6)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="4" className="survey-btn" onChange={() => this.props.handleRadioClick('question4', 4)}><BsStarFill /></ToggleButton>
						<ToggleButton type="radio" name="radio" value="2" className="survey-btn" onChange={() => this.props.handleRadioClick('question4', 2)}><BsStarFill /></ToggleButton>
					</ToggleButtonGroup>
				</div>

				{stateProp.showComment && <div className="pt-1 pb-2">
					<textarea className="form-control pb-2" placeholder="Comments" onChange={this.handleSurveyCommentChange}></textarea>
				</div>}
				
				<div className="btn btn-primary btn-block py-2 mt-3 submit-button d-block" onClick={() => this.props.handleRadioSubmit()}>Leave your review</div>
			</div>
		)
	}
}
export default QuestionRatings